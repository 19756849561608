* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   outline: none;
}

body {
   width: 100%;
   height: 100%;
   background-color: #F4F4F4;
   font-family: sans-serif;
}

a {
   text-decoration: none;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
span {
   font-size: 18px;
   color: #fff;
   font-family: Poppins, sans-serif;
} */

li {
   overflow: hidden;
}

p {
   font-size: 16px;
   font-family: Poppins, sans-serif;
   color: #000;
   
}

.container {
   width: 80%;
   margin: 0px auto;
   padding-top: 60px;
} 
 
/* .white {
    color: #fff !important;
} */

.d__flex {
   display: flex;
}

/* .row {
   display: flex;
   width: 100%;
   flex-wrap: wrap;
   overflow: hidden;
   align-items: flex-start;
   justify-content: center;
} */
.col__4{
   max-width: 40%;
   display: flex;
   padding: 20px;
   justify-content: center;
   flex-direction: column;
}
.col__6{
   max-width: 60%;
   display: flex;
   padding: 20px;
   justify-content: center;
   flex-direction: column;
}

.col__2{
   max-width: 50%;
   display: flex;
   padding: 20px;
   justify-content: center;
   flex-direction: column;
}
.component__space {
   padding: 100px 0;
}

.pointer {
   cursor: pointer;
}

svg {
   stroke-width: 1 !important;
   margin-top: 10px;
   color: #FFF;
}

button.btn {
   font-size: 18px;
   font-weight: 500;
   border-radius: 5px;
   border: 2px solid #009640;
   background-color: #009640;
   color: #fff !important;
   text-transform: uppercase;
   letter-spacing: 2px;
   transition: .4s all ease;
   height: 50px;
   width: 180px;
   margin: 0 10px;
}

.p__color {
   color: rgba(13, 13, 14, 0.75);
   font-size: 15px;
   line-height: 30px;
   font-family: Poppins, sans-serif;
   margin-bottom: 15px;
   text-align: justify;
}

button.about.btn.pointer:hover {
   background: #fff;
   color: #000 !important;
}

.box-header-logo img {
   width: 300px;
   margin: 30px;
}


@media only screen and (max-width: 768px) {
     .col__2 {
      max-width: 100%;
     }
}
 
.col__3 {
   max-width: 33.333333%;
   display: flex;
   padding: 20px;
   justify-content: center;
   flex-direction: column;
   margin-top: 20px;
}

.component__space {
   padding: 100px 0px; 
}

.relative {
   position: relative;
}

.absolute {
   position: absolute;
}

@media only screen and (max-width: 768px) {
     .col__3 {
      max-width: 50%;
     }
     
}

@media only screen and (max-width: 425px) {
   .col__3 {
    max-width: 100%;
   }
   
}

