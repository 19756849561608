.contact-box {

    background-color: #F4F4F4;
}

h1.hire__text {
    font-size: 60px;
    color: #009640;
    text-shadow: 1px 1px 2px black;
}
p.hire__text {
    font-size: 18px;
    color: #000;
    line-height: 30px;
    font-weight: 400;
}

input.contact {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0,0,0, .1);
    border-radius: 5px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    height: 50px;
    line-height: 46px;
    margin: 20px 0px;
    outline: none;
    color: #000;
    font-size: 15px;
    letter-spacing: .1px;
    background: white;
    border: 2px solid hsla(133, 96%, 18%, 0.2);
}

.contact__img {
    border-radius: 10px;
    width: 90%;
    margin-left: 5%;
    overflow: hidden;
    box-shadow: 2px 2px 2px 1px hsla(133, 96%, 18%, 0.2);
}

textarea#message {
    height: 120px;
    background: white;
    padding: 5px 20px;
    width: 100%;
    /* line-height: 46px; */
    color: #555;
    font-size: 15px;
    letter-spacing: .1px;
    border: 2px solid hsla(133, 96%, 18%, 0.2);
}

button.btn.contact.pointer {
    margin: 20px 0px;
}

button.view__more:hover {
    background-color: #fff;
    border: 2px solid hsla(133, 96%, 18%, 0.2);
    color: #009640 !important;
}

@media only screen and (max-width: 768px) {
    h1.hire__text {
        font-size: 30px;
        color: #009640;
        text-shadow: unset;
        margin-top: -120px;
    }
    .contact__img {
        border-radius: 10px;
        width: 100%;
        margin-left: 0%;
        overflow: hidden;
        box-shadow: 2px 2px 2px 1px hsla(133, 96%, 18%, 0.2);
    }
 }