
.Navbar {
    padding: 0px 25px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Navbar>.nav-items>a {
    color: #009640;
    font-size: 18px;
    text-decoration: none;
    margin: 15px;
    padding: 15px 0;
    position: relative;
    opacity: 1.9;
    font-weight: bold;
}
.Navbar img {
    width: 180px;
}
.Navbar>.nav-items>a:hover {
    color: #06db4d;
}

.Navbar>.nav-items>a:hover {
    opacity: 1;
}

.Navbar>.nav-items>a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    background: #f9e800;
    transition: all 0.45s;
}

.Navbar>.nav-items>a:hover::before {
    width: 100%;
}

.Navbar>.nav-toggle {
    display: none;
}

@media (max-width: 768px) {
    .Navbar>.nav-items {
        position: absolute;
        top: 40px;
        display: flex;
        margin-top: 30px;
        padding-top: 20px;
        flex-direction: column;
        background-color: #004F9F;
        left: 0;
        width: 100%;
        height: auto;
        transform: translateX(-100%);
        transition: all .45s;
        
    }
    .Navbar>.nav-items>a {
        padding-left: 30px;
        margin: 0px;
        font-size: 18px;
        background-color: #f4f4f4;
    }
    .Navbar>.nav-items>a.active {
        color: #004F9F; /* Cor de destaque para o item ativo */
        font-weight: bold;
    }
    
    .Navbar>.nav-items>a::before {
        background: transparent;
    }
    .Navbar>.nav-items.open {
        transform: translateX(0);
        
    }
    .Navbar>.nav-toggle {
        display: flex;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .nav-toggle>.bar {
        position: relative;
        width: 32px;
        height: 2px;
        background: green;
        transition: all 0.45s ease-in-out;
    }
    .nav-toggle>.bar::before,
    .nav-toggle>.bar::after {
        content: "";
        position: absolute;
        height: 2px;
        background: green;
        border-radius: 2px;
        transition: all 0.45s ease-in-out;
    }
    .nav-toggle>.bar::before {
        width: 25px;
        transform: translateY(-8px);
        right: 0;
    }
    .nav-toggle>.bar::after {
        width: 32px;
        transform: translateY(8px);
    }
    .nav-toggle.open>.bar {
        transform: translateX(-40px);
        background: transparent;
    }
    .nav-toggle.open>.bar::before {
        width: 32px;
        transform: rotate(45deg) translate(26px, -26px);
    }
    .nav-toggle.open>.bar::after {
        transform: rotate(-45deg) translate(26px, 26px);
    }
    .logo {
        width: 170px;
    }
}

.home__bg {
    /* background-image: url(img/header.png);
    background-color: #F4F4F4;
    max-width: 100%; */
    /* background-position: 150%; */
    /* background-repeat: no-repeat;
    background-size: cover; */
}

.home__content {
    /* padding: 125px 0px; */
}

.home__meta {
    /* padding-right: 233px; */
   width: 100%;
}
.home__meta img {
    width: 100%;
}

h2.frelencer_content {
    font-size: 50px;
    line-height: 1.3;
    letter-spacing: 3px;
    color: #FFF;
}

h4.frelencer_content {
    font-size: 40px;
    line-height: 1.3;
    letter-spacing: 3px;
    color: #FFF;
}

h2.frelencer_content span {
    font-size: 30px;
    font-weight: 900;
    color: #fff;
    letter-spacing: 0 !important;
}

/* .header {
    transition: .4s all ease;
}

.header.active {
    position: fixed;
    z-index: 1000;
    width: 100%;
    padding: 0px 30px;
    background-color: #F4F4F4;
} */
.header {
    transition: 0.4s all ease;
}

.header.active {
    position: fixed;
    z-index: 1000;
    width: 100%;
    padding: 0px 30px;
    background-color: #F4F4F4;
}

/* .header.active a {
    color: #048846 !important;
}

.header.active a:hover {
    color: #036936 !important;
} */

/* RESPONSIVE */

@media only screen and (max-width: 768px) {
    .header {
        justify-content: space-between;
    }
    .logo {
        width: 80px;
    }
    h2.frelencer_content {
        font-size: 30px;
        /* line-height: 0; */
        letter-spacing: 1px;
    }
    h2.frelencer_content span {
        font-size: 30px;
        font-weight: 900;
        color: #009640;
        letter-spacing: 0 !important;
    }
    h4.frelencer_content {
        font-size: 30px;
        /* line-height: 0; */
        letter-spacing: 1px;
        color: #fff;
        text-shadow: 1px 1px 2px black;
    }
    .home__meta {
        padding-right: revert;
    }
    .home__bg {
        /* background-image: url(img/Screenshot_2.png); */
        background-color: #F4F4F4;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media only screen and (max-width: 425px) {
    .logo {
        width: 80px;
    }
    h2.frelencer_content {
        font-size: 30px;
        /* line-height: 0; */
        letter-spacing: 1px;
    }
    h2.frelencer_content span {
        font-size: 30px;
        font-weight: 900;
        color: #009640;
        letter-spacing: 0 !important;
    }
}