.social-media {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-right: 20px;
}
.social-media img {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  margin-right: 10px;
}