.about {
    background-color: #fff;
}

img.about__img {
    border-radius: 10px;
    width: 100%;
}

h1.about__heading {
    margin-bottom: 10px;
    font-weight: 700;
    text-align: start;
    font-family: Montserrat, sans-serif;
    font-size: 60px;
    color: #091778;
    text-shadow: 1px 1px 2px black;
}

h2 {
    font-size: 20px !important;
    color: #091778 !important;
}

h3 {
    font-size: 36px !important;
    color: #091778 !important;
}

button.about.btn.pointer {
    text-transform: capitalize;
    width: 195px;
    height: 55px;
    font-size: 18px;
    transition: .4 ease;
}

.about__button {
    padding: 20px 0px;
}

a.bottom__to__top {
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 5%;
    right: 2%;
    background: #009640;
    border-radius: 50%;
    cursor: pointer;
    align-content: center;
    display: none;
    justify-content: center;
    transition: .4s ease;
    z-index: 1000;
}

a.bottom__to__top.active {
    display: flex;
}
.box-header {
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    box-shadow: inset 0em 0.8em gold;
    padding: 20px;    
    border-top: 0.8em solid green;
}
.box-header-logo img {
    width: 190px;
}
.box-header-pl img{
    width: 125px;
}

@media only screen and (max-width: 768px) {
    button.about.btn.pointer {
        text-transform: capitalize;
        width: 165px;
        height: 50px;
        font-size: 13px;
        transition: .4s ease;
    }
    h1.about__heading {
        margin-bottom: 10px;
        font-weight: 700;
        text-align: start;
        font-family: Montserrat, sans-serif;
        font-size: 30px;
        color: #091778;
        text-shadow: 1px 1px 2px black;
        margin-top: 20px;
    }
}