 h1.heading {
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    font-family: Poppins, sans-serif;
    margin-bottom: 10px;
    color: #004F9F;
    text-shadow: 1px 1px 2px black;
    margin-top: -50px;
 }

 p.heading.p__color {
    margin-bottom: 0;
    text-align: center;
    line-height: 1.2;
    color: #028a20 !important;
 }

 .service__box {
    border-radius: 10px;
    width: 100%;
    background-color: #004F9F;
    padding: 40px;
    transition: .4s ease;
    margin-bottom: 20px;
 }

 .service__box:hover {
    transform: translateY(5px);
    background-image: linear-gradient(90deg, #f5e501, #028a20);
 }

 .service__box:hover .icon {
    color: #fff;
 }

 .service__box:hover p.service__text .p__color {
    color: #fff !important;
 }

 .icon img {
    width: 50px;
    margin-bottom: 23px;
    display: inline-flex;
    color: #FFD500;
 }

 p.service__text.p__color {
    margin-bottom: 0;
    color: #f4f4f4;
 }

 h1.service__text {
    font-size: 19px;
    font-family: Poppins, sans-serif;
    padding: 10px 0px;
    text-transform: uppercase;
 }
 .service__meta {
   color: #FFF;
 }

 @media only screen and (max-width: 1024px) {
    .service__box {
       padding: 32px;
    }
 }
 @media only screen and (max-width: 768px) {
   .service__box {
      padding: 32px;
      margin-bottom: 10px;
   }
   h1.heading {
      font-size: 30px;
      text-align: center;
      font-weight: 700;
      font-family: Poppins, sans-serif;
      margin: 10px;
      color: #004F9F;
      text-shadow: unset;
      margin-top: -50px;
   }
}