/* .Blog__meta {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Blog__meta.absolute {
    top: 0%;
    padding: 0px 20px;
    left: 0%;
    display: flex;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
} */
.card-body img {
    width: 100%;
    border-radius: 4px;
}

/* Estilos base do .card */
.card {
    /* margin-bottom: 20px; Defina um valor inicial para margin-bottom */
    color: black; /* Cor inicial */
    width: 100%; /* Largura inicial */
    box-shadow: 1px 1px 1px 1px green; /* Sombra inicial leve */
    transition: all 0.4s ease; /* Defina transições aqui */
}

/* Estilos de :hover para .card */
.card:hover {
    margin-bottom: 0px; /* Remova a margem na parte inferior */
    color: #FFF; /* Cor ao passar o mouse */
    width: 103%; /* Aumente a largura ao passar o mouse */
    box-shadow: 2px 2px 2px 2px #555; /* Sombra mais proeminente ao passar o mouse */
}
.texto {
    margin-top: 5px;
    color: #555;
    font-size: 14px;
}
.texto-p {
    margin-top: 5px !important;
    margin-left: 15px !important;
    font-size: 12px !important;
    color: #555 !important;
}

